/* eslint-disable max-len */
import { config } from 'core/config';

export const fr = {
  translation: {
    'app-subscription-4-7d-trial-per-day': {
      gender: {
        title: "Comprenez-vous vous-même et améliorez vos relations grâce à l'astrologie",
        subtitle: "Évaluation personnelle d'une minute",
        signIn: "Vous avez déjà un compte ? S'identifier",
        button: 'Faire le test',
        address: `${config.companyName}, Athalassas, 62, MEZZANINE, Strovolos, 2012, Nicosie, Chypre`,
        actionTitle: 'Sélectionnez votre sexe :',
        answers: {
          male: 'homme',
          female: 'femme',
        },
      },
      mentionedIn: {
        title: '42+ millions de personnes',
        subtitle: 'utilisent déjà Nebula',
        testimonial:
          'Nebula vise à éclairer les connexions interpersonnelles en utilisant des signes astrologiques',
        mentionedInText: 'MENTIONNÉ DANS',
        next: 'Continuer',
      },
      flowChoice: {
        title:
          'Afin de mieux vous connaître, dîtes-nous dans quel situation relationnelle vous êtes',
        single: 'Célibataire',
        relationship: 'En couple',
        married: 'Marié(e)',
        complicated: 'Compliqué',
        unsure: 'Incertain / Autre',
      },
      relationshipGoalSingle: {
        title: 'Quel est votre objectif ?',
        answers: {
          perfectPartner: 'Trouver le partenaire idéal',
          understandBetter: 'Mieux me comprendre',
          getMarried: 'Se marier',
          happiness: 'Atteindre le bonheur',
          growth: 'Croissance personnelle',
          compatibility: 'Vérifier la compatibilité',
          all: 'Tous les points ci-dessus',
        },
        store: {
          perfectPartner: 'trouver votre partenaire parfait',
          understandBetter: 'mieux vous comprendre',
          loadingBoth: 'trouver votre partenaire parfait et mieux vous comprendre',
          getMarried: "trouver l'amour pour votre mariage",
          happiness: 'vivre heureux',
          growth: "s'épanouir sur le plan personnel",
          compatibility: 'vérifier la compatibilité',
          findLoveAndUnderstandBetter: "trouver l'amour et mieux se comprendre",
        },
      },
      goalSetup: {
        title: 'Super ! Vous venez de définir votre premier objectif !',
        text: 'Continuons pour mieux vous connaître',
        next: 'Suivant',
        back: 'Retour',
      },
      parent: {
        title: 'Êtes-vous parent ?',
        answers: { yes: 'Oui', no: 'Non' },
      },
      astrologyKnowledge: {
        title: 'Quel est votre niveau de connaissance en <br /> astrologie ?',
        subtitle: 'Nous pouvons ainsi adapter les informations à votre niveau de connaissance',
        answers: { expert: 'Expert', curious: 'Curieux', beginner: 'Débutant' },
      },
      whatIs: {
        title: "Qu'est-ce que <b>l'astrologie hyper-personnalisée</b> ?",
        text:
          "L'astrologie personnalisée est basée sur la compréhension que chacun a un modèle astrologique unique. " +
          'La position des étoiles dans le ciel à votre date de naissance, lieu de naissance et heure de naissance ' +
          "sont uniques et ne se produisent qu'une seule fois dans une vie. Vous êtes plus que votre signe du " +
          'zodiaque !' +
          '</br>' +
          '</br>' +
          '<b>Alors comment ça <br />marche ?</b>' +
          '</br>' +
          'Nous analysons des centaines de points de données pour créer votre empreinte astrologique unique. ' +
          "Ce système est combiné à l'IA pour personnaliser vos connaissances astrologiques en fonction de vos " +
          "réponses. Nous allons changer votre relation avec l'astrologie.",
        next: 'Suivant',
      },
      date: {
        title: 'Quelle est votre date de naissance',
        next: 'Suivant',
      },
      singleZodiacInfo: {
        next: 'Suivant',
      },
      birthTime: {
        title: 'Connaissez-vous votre heure de naissance ?',
        answers: { yes: 'Oui', no: 'Non' },
      },
      time: {
        title: 'A quelle heure êtes-vous né ?',
        text:
          'Nous utilisons les données de la NASA pour identifier la position exacte des planètes dans le ciel à ' +
          "l'heure de votre naissance.",
        label: '',
        next: 'Suivant',
      },
      noTime: {
        title:
          '<strong>Pas de problème ! Vous aurez quand même de nombreux bons conseils sans connaître votre heure de' +
          ' naissance exacte.</strong>',
        text: 'Astuce : si vous le découvrez plus tard, vous pouvez le modifier dans les paramètres de votre profil.',
        next: 'Suivant',
        back: 'Retour',
      },
      place: {
        title: 'Où êtes-vous né ?',
        text: "Cela détermine le fuseau horaire de l'endroit de votre naissance.",
        next: 'Suivant',
        cancel: 'Annuler',
      },
      perfectPartner: {
        titleStart: 'Nous avons aidé ',
        titleMiddle: " d'autres personnes ",
        titleEnd:
          ' à trouver leur partenaire parfait et nous attendons avec impatience de vous aider aussi !',
        subtitle: '*à partir du 24 février 2023',
        list: ['Connexion à la base de données...'],
      },
      understandBetter: {
        titleStart: 'Nous avons aidé ',
        titleMiddle: ' autres ',
        titleEnd: ' à mieux se comprendre et nous attendons avec impatience de vous aider aussi !',
        subtitle: '*à partir du 24 février 2023',
        list: ['Connexion à la base de données...'],
      },
      loadingBoth: {
        titleStart: 'Nous avons aidé ',
        titleMiddle: ' autres ',
        titleEnd:
          ' pour mieux se comprendre et trouver leur partenaire parfait et nous attendons avec impatience de vous ' +
          'aider aussi !',
        subtitle: '*à partir du 24 février 2023',
        list: ['Connexion à la base de données...'],
      },
      singleProblem: {
        young: 'moins de 20 ans',
        old: 'plus de 60 ans',
        adult: 'dans leur',
        male: 'homme',
        femme: 'femme',
        parent: 'qui ont des enfants',
        title: {
          relationship:
            " ont besoin d'une approche légèrement " +
            'différente pour améliorer leur relation. Quelle déclaration vous décrit le mieux ?',
          singleStart: 'Les célibataires {{translate gender}}',
          singleEnd:
            "ont besoin d'une approche légèrement différente pour trouver leur partenaire idéal. Mais d'abord, comment vous sentiez-vous dans " +
            'votre dernière relation ?',
        },
        answers: {
          things:
            "Je n'étais pas satisfait de la façon dont les choses se passaient dans ma relation",
          parts:
            "Je n'étais pas satisfait de certaines parties de ma relation, mais certaines choses fonctionnaient",
          happy: 'Je suis généralement heureux de ma relation',
          never: "Je n'ai jamais été dans une relation",
        },
      },
      traitsWorks: {
        title:
          'Pour savoir ce qui fonctionne pour nous, nous devons souvent comprendre ce qui ne fonctionne pas. ' +
          'Nous vous donnerons des conseils sur la manière de construire une relation heureuse et durable avec ' +
          'votre nouveau partenaire',
        text:
          'Nous allons vous donner des informations sur la façon de construire une relation heureuse et durable ' +
          'avec votre nouveau partenaire.',
        next: 'Suivant',
        back: 'Retour',
      },
      traitsMoveOn: {
        title:
          'Parfois, cela se produit et vous devez passer à autre chose ! Nous vous donnerons des informations sur ' +
          'la façon de construire une relation durable avec votre prochain partenaire.',
        text:
          "Maintenant, nous avons besoin d'informations sur vos <b>Traits de personnalité</b> " +
          'pour aller plus loin  !',
        next: 'Suivant',
        back: 'Retour',
      },
      traitsCompatible: {
        title:
          'Eh bien, nous allons bien nous amuser ! Nous vous donnerons des informations sur votre partenaire le plus ' +
          'compatible.',
        text:
          "Maintenant, nous avons besoin d'informations sur vos <b>traits de personnalité</b> " +
          'pour creuser plus profondément !',
        next: 'Suivant',
        back: 'Retour',
      },
      relateToStatement: {
        title: "Est-ce que tu te reconnais dans l'affirmation ci-dessous ?",
        subtitle:
          "<strong>“Je déteste vraiment être seul, même si je déteste l'admettre.”</strong>",
        answers: { yes: 'Oui', no: 'Non' },
      },
      notice: {
        title: 'Avez-vous tendance à remarquer ce qui ne va pas, plus que ce qui va <br />bien ?',
        answers: {
          veryFrequently: 'Très fréquemment',
          frequently: 'Fréquemment',
          occasionally: 'Occasionnellement',
          rarely: 'Rarement',
          never: 'Jamais',
        },
      },
      sensitive: {
        title: 'Êtes-vous sensible aux critiques ?',
        answers: { yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement', notAtAll: 'Pas du tout' },
      },
      tendToOverthink: {
        title: 'Est-ce que vous avez tendance à trop réfléchir ?',
        answers: { yes: 'Oui', no: 'Non' },
      },
      mostImportant: {
        title: "Qu'est-ce qui est le plus important pour vous ?",
        answers: {
          success: 'Succès',
          romance: 'Romance',
          stability: 'Stabilité',
          freedom: 'Liberté',
          happiness: 'Le bonheur',
          health: 'Santé',
          all: 'Tous les points ci-dessus',
        },
      },
      emotionalControl: {
        title: 'Est-ce que contrôler vos émotions est difficile ?',
        answers: { yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement', notAtAll: 'Pas du tout' },
      },
      notAlone: {
        title: `Vous n’êtes pas seul.<br/>Beaucoup de <span>{{translate zodiac}}</span> gens trouvent difficile de contrôler leurs émotions en raison de leurs traits de signe astrologique. Ne vous inquiétez pas, nous savons comment aborder cela `,
        next: 'Suivant',
        back: 'Retour',
      },
      allRight: {
        title: `D'accord !<br/> Vous contrôlez vos émotions <span>{{translate zodiac}}</span>, mais parfois les gens peuvent vouloir que vous montrez plus de passion. Nous pouvons vous donner quelques informations à ce sujet !`,
        next: 'Suivant',
        back: 'Retour',
      },
      attitude: {
        title: 'Aimez-vous votre travail ?',
        answers: {
          doWhatLove: "Oui ! Je fais quelque chose que j'aime",
          notWorking: 'Je ne travaille pas',
          hateJob: 'Je déteste mon travail',
          paysBills: 'Ça suffit, ça paye les factures',
          underPaid: 'Je suis sous-payé',
          growing: "J'ai cessé de progresser professionnellement",
        },
      },
      want: {
        title: 'Savez-vous toujours exactement ce que vous voulez ?',
        answers: { yes: 'Oui', no: 'Non', notSure: 'Pas sûr' },
      },
      relaxing: {
        title: 'Avez-vous des difficultés à vous détendre ?',
        answers: { notAtAll: 'Pas du tout', yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement' },
      },
      almostThere: {
        title:
          'Presque fini !' +
          '<br/>' +
          'Maintenant, faisons un plan personnalisé en' +
          ' comprenant le <b>modèle de relation</b>.',
        text:
          'Veuillez prendre votre temps pour répondre. Chaque réponse est utilisée pour créer votre ' +
          'plan de guidance.',
        next: 'Suivant',
        back: 'Retour',
      },
      priority: {
        title: "Êtes-vous d'accord avec l'affirmation ci-dessous ?",
        subtitle: '<strong>“Lorsque je suis en couple, le sexe est une priorité.”</strong>',
        answers: {
          stronglyAgree: "Tout à fait d'accord",
          agree: "D'accord",
          neutral: 'Neutre',
          disagree: "Pas d'accord",
          stronglyDisagree: "Pas du tout d'accord",
        },
      },
      comfortable: {
        title: "Êtes-vous d'accord avec l'affirmation ci-dessous ?",
        subtitle: "<strong>“Je me sens à l'aise avec des gens que je connais à peine.“</strong>",
        answers: {
          notAtAll: 'Pas du tout',
          yes: 'Oui',
          sometimes: 'Parfois',
          rarely: 'Rarement',
          // Experiment quiz visual element
          // TODO: Remove unnecessary answers after end test
          stronglyAgree: "Tout à fait d'accord",
          agree: "D'accord",
          neutral: 'Neutre',
          disagree: "Pas d'accord",
          stronglyDisagree: "Pas du tout d'accord",
        },
      },
      goodEnough: {
        title: 'Vous inquiétez-vous souvent de ne pas être assez bien ?',
        answers: { notAtAll: 'Pas du tout', yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement' },
      },
      angry: {
        title: 'Vous vous mettez facilement en colère ou êtes-vous irrité ?',
        answers: { notAtAll: 'Pas du tout', yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement' },
      },
      innerSelf: {
        title: 'Partagez-vous votre vrai moi avec les gens que vous aimez ?',
        answers: { yes: 'Oui', no: 'Non', notSure: 'Pas sûr' },
      },
      aboutPeople: {
        title:
          'Est-ce que vous vous souvenez des petits détails sur les personnes qui sont importantes pour vous ?',
        answers: { notAtAll: 'Pas du tout', yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement' },
      },
      idealDate: {
        title: 'Quelle est votre date idéale ?',
        answers: {
          restaurant: 'Dîner dans un restaurant chic',
          nature: 'Une randonnée dans la nature',
          talking: 'Parler sous les étoiles',
          walk: 'Une promenade dans le parc',
          skydiving: 'Parachutisme',
        },
      },
      futurePartner: {
        title:
          "Décririez-vous votre partenaire futur comme quelqu'un qui s'intéresse aux détails ou à la vie en général ?",
        answers: {
          detailOriented: "S'intéresser aux détails",
          bigPicture: 'Voir les choses dans leur ensemble',
          both: 'Un peu des deux',
        },
      },
      idealPartner: {
        title: 'Votre partenaire idéal est-il introverti ou extraverti ?',
        answers: { introvert: 'Introverti', extrovert: 'Extraverti', both: 'Un peu des deux' },
      },
      relationshipGoal: {
        title: 'Quand vous pensez à vos objectifs de relation, vous ressentez... ?',
        answers: {
          rightPlace: 'Optimiste ! Ils sont totalement réalisables, avec un peu de guidance',
          thing: "Prudent. J'ai déjà eu du mal, mais je suis optimiste",
          totallyNormal: 'Je me sens un peu anxieux, honnêtement',
        },
      },
      rightPlace: {
        title: `Vous êtes au bon endroit<br/><span>{{translate zodiac}}</span> !`,
        subtitle:
          'Savoir que votre objectif est déjà réalisable vous met en avance. ' +
          "Continuons afin que nous puissions élaborer un plan d'orientation basé sur votre schéma astrologique.",
        next: 'Suivant',
        back: 'Retour',
      },
      thing: {
        title: `<span>{{translate zodiac}}</span>, voici la chose...<br/>Vous pouvez le faire !`,
        subtitle:
          'Nous commencerons par de petites intuitions pour ne pas trop nous submerger. Continuons ' +
          'pour construire un plan de guidage basé sur votre plan astrologique.',
        next: 'Suivant',
        back: 'Retour',
      },
      totallyNormal: {
        title: `Il est tout à fait normal d'être stressé,<br/><span>{{translate zodiac}}</span>.`,
        subtitle:
          'Mais nous savons que vous pouvez le faire. ' +
          'Continuons et construisons un plan de guidage basé sur votre modèle astrologique.',
        next: 'Suivant',
        back: 'Retour',
      },
      communicate: {
        title: 'Trouvez-vous facile de communiquer avec les autres personnes ?',
        answers: { yes: 'Oui', no: 'Non' },
      },
      decisionsSingle: {
        title: 'Prenez-vous des décisions avec votre tête ou votre cœur ?',
        answers: { heart: 'Cœur', head: 'Tête', both: 'Les deux' },
      },
      withHeartSingle: {
        title: `Selon nos données, 49% des <span>{{translate zodiac}}</span> personnes prennent également des décisions avec leur cœur. Ne vous inquiétez pas, nous prendrons cela en compte lors de la création de votre plan de guidance`,
        next: 'Suivant',
        back: 'Retour',
      },
      withHeadSingle: {
        title: `Selon nos données, 39% des <span>{{translate zodiac}}</span> personnes prennent également des décisions avec leur tête. Ne vous inquiétez pas, nous prendrons cela en compte lors de la création de votre plan de guidance.`,
        next: 'Suivant',
        back: 'Retour',
      },
      bothSingle: {
        title: `Merveilleux !<br/>Selon nos données, seuls les 17% <span>{{translate zodiac}}</span> des personnes les plus haut prennent des décisions avec leur tête et leur cœur. Utiliser les deux en quantité égale est la clé pour se sentir harmonieux dans vos relations.`,
        next: 'Suivant',
        back: 'Retour',
      },
      aboutUsSingle: {
        title: 'Où avez-vous entendu parler de nous ?',
        answers: {
          poster: 'Affiche ou panneau publicitaire',
          friend: 'Ami ou famille',
          instagram: 'Instagram',
          mail: 'Courrier direct ou insert de colis',
          streaming: 'Télévision en ligne ou télévision en streaming',
          tv: 'Télévision',
          radio: 'Radio',
          search: 'Moteur de recherche (Google, Bing, etc.)',
          newspaper: 'Journal ou magazine',
          facebook: 'Facebook',
          review: 'Article de blog ou critique de site Web',
          podcast: 'Podcast',
          influencer: 'Influenceur',
          youtube: 'Youtube',
          pinterest: 'Pinterest',
          other: 'Autre',
        },
      },
      relationshipGoalInARelationship: {
        title: 'Quel est votre objectif ?',
        answers: {
          fixRelationship: 'Résoudre les problèmes de relation',
          increaseSatisfaction: 'Augmenter la satisfaction de votre relation',
          buildStrongMarriage: 'Construire un mariage solide',
          checkCompatibility: 'Vérifier la compatibilité',
          all: 'Tous les points ci-dessus',
        },
        store: {
          fixRelationship: 'Résoudre les problèmes de relation',
          improveRelationship: 'améliorer votre relation',
          buildStrongMarriage: 'construire un mariage solide',
          checkCompatibility: 'vérifier la compatibilité',
          increaseSatisfaction: 'Augmenter la satisfaction de votre relation',
          both: 'résoudre les problèmes de relation et augmenter la satisfaction de votre relation',
        },
      },
      loadingInRelationship: {
        titleStart: 'Nous avons aidé ',
        titleMiddle: ' avec leur Soleil dans ',
        titleEnd:
          ' pour améliorer leur relation et nous ne pouvons pas attendre de vous aider aussi !',
        titleEndShort: 'et nous sommes impatients de vous aider à notre tour!',
        subtitle: '*à partir du 24 février 2023',
        goal: {
          perfectPartner: 'pour trouver le partenaire idéal',
          understandBetter: 'de mieux se comprendre',
          growth: "s'épanouir sur le plan personnel",
          getMarried: 'se marier',
          achieveHappiness: 'atteindre le bonheur',
          checkCompatibility: 'vérifier la compatibilité',
          increaseSatisfaction: 'augmenter la satisfaction de votre relation',
          fixRelationship: 'résoudre les problèmes de relation',
          buildStrongMarriage: 'construire un mariage solide',
          allAboveSingle: 'avec un accompagnement personnalisé',
          allAboveRelationship: 'avec des conseils en matière de relations',
        },
        list: ['Connexion à la base de données...'],
      },
      relationshipProblem: {
        answers: {
          absolutelyUnhappy:
            'Je suis très malheureux de la façon dont les choses se passent dans ma relation',
          particularyHappy:
            'Je suis malheureux avec certaines parties de ma relation, mais certaines choses fonctionnent bien',
          generallyHappy: 'Je suis généralement heureux dans ma relation',
        },
      },
      worksForUs: {
        title:
          'Nous avons tout couvert ! Nous commencerons par des petites informations personnalisées' +
          'sur les traits de personnalité de vous et de votre partenaire',
        text:
          "Maintenant, nous avons besoin d'informations sur <b>le profil de votre partenaire</b> pour créer" +
          ' votre plan sy astrique entre vous et votre partenaire',
        next: 'Suivant',
        back: 'Retour',
      },
      moveOn: {
        title:
          'Vous allez probablement mieux que vous ne le pensez ! Nous vous aiderons à identifier comment améliorer ' +
          'et à vous y tenir',
        text:
          "Maintenant, nous avons besoin d'informations sur <b>le profil de votre partenaire</b> pour créer " +
          'le plan astrologique synastrique entre vous et votre partenaire.',
        next: 'Suivant',
        back: 'Retour',
      },
      mostCompatible: {
        title:
          'Merveilleux !' +
          '<br/>' +
          'Voyons ce qui fonctionne (et ce qui ne fonctionne pas) et partons de là',
        text:
          "Maintenant, nous avons besoin d'informations sur <b>le profil de votre partenaire</b> pour créer " +
          'le plan astrologique synastrie entre vous et votre partenaire.',
        next: 'Suivant',
        back: 'Retour',
      },
      partnerGender: {
        title: 'Quel est le genre de votre partenaire?',
        answers: {
          male: 'Homme',
          female: 'Femme',
        },
      },
      partnerDate: {
        title: 'Quelle est la date de naissance de votre partenaire ?',
        text:
          'La date de naissance de votre partenaire est une information essentielle pour construire le plan ' +
          'astrologique personnel de votre partenaire.',
        // TODO: refactor after close test quiz with visual elements
        experimentText: 'Cela met en évidence la dualité de vos énergies masculines et féminines.',
        next: 'Suivant',
      },
      relationshipZodiacInfo: {
        next: 'Suivant',
        captionTitle: 'Alors, à quel point êtes-vous compatibles?',
        captionDescription: 'Allons plus loin et découvrons-le.',
      },
      partnerBirthTime: {
        title: 'Savez-vous à quelle heure votre partenaire est né ?',
        answers: { yes: 'Oui', no: 'Non' },
      },
      partnerTime: {
        title: 'A quelle heure votre partenaire est-il né ?',
        text:
          'Nous utilisons les données de la NASA pour identifier la position exacte' +
          'des planètes dans le ciel au moment de la naissance de votre partenaire.',
        label: '',
        next: 'Suivant',
      },
      partnerNoTime: {
        title:
          "<strong>Pas de problème ! Sans connaître l'heure de naissance exacte de votre partenaire, " +
          'vous pouvez encore trouver de nombreuses informations intéressantes.</strong>',
        text: "Conseil : si vous l'apprenez plus tard, vous pouvez l'ajuster dans le profil de votre partenaire",
        next: 'Suivant',
        back: 'Retour',
      },
      partnerPlace: {
        title: 'Où votre partenaire est-il né ?',
        text: 'Si vous ne connaissez pas le lieu de naissance exact, indiquez simplement le pays de naissance.',
        next: 'Suivant',
        cancel: 'Annuler',
      },
      relationshipAlmostThere: {
        title:
          'Presque là !' +
          '<br/>' +
          'Commençons maintenant à adapter votre plan en comprenant vos ' +
          '<b>modèles de relation et de personnalité</b>.',
        text:
          'Prenez votre temps pour répondre. ' +
          'Chaque réponse est utilisée pour créer le plan de guidance pour vous et votre partenaire',
        next: 'Suivant',
        back: 'Retour',
      },
      issueTogether: {
        title: "Êtes-vous d'accord avec l'affirmation ci-dessous ?",
        subtitle:
          '<strong>“Mon partenaire et moi pouvons discuter de tout problème ensemble”</strong>',
        answers: {
          stronglyAgree: "Tout à fait d'accord",
          agree: "D'accord",
          neutral: 'Neutre',
          disagree: "Pas d'accord",
          stronglyDisagree: "Pas du tout d'accord",
        },
      },
      currentlyAffecting: {
        title: "Est-ce que l'un de ces facteurs affecte actuellement votre relation ?",
        answers: {
          infidelity: 'Infidélité',
          depression: 'Dépression',
          lowSexualDesire: 'Faible désir sexuel',
          poorBodyImage: 'Mauvaise image de soi',
          postpartumDepression: 'Dépression post-partum',
          anxiety: 'Anxiété',
          parentingIssues: 'Problèmes de famille',
          blendedFamilyIssues: 'Problèmes de famille recomposée',
          divorceIsOnTheTable: 'Le divorce est sur la table',
          noneOfThese: 'Rien de tout ça',
        },
        next: 'Suivant',
      },
      partnerPriority: {
        title: "Êtes-vous d'accord avec l'affirmation ci-dessous ?",
        subtitle:
          '<strong>“Mon partenaire et moi faisons de la sexualité une priorité dans notre relation”</strong>',
        answers: {
          stronglyAgree: "Tout à fait d'accord",
          agree: "D'accord",
          neutral: 'Neutre',
          disagree: "Pas d'accord",
          stronglyDisagree: "Pas du tout d'accord",
        },
      },
      satisfied: {
        title: 'Êtes-vous satisfait de la façon dont vous et votre partenaire communiquez ?',
        answers: { yes: 'Oui', no: 'Non' },
      },
      satisfiedYes: {
        title: `Bien joué !<br/>Selon nos données, seuls les meilleurs 30% <span>{{translate zodiac}}</span> trouvent facile de communiquer avec leur <span>{{translate partnerZodiac}}</span> partenaire.`,
        next: 'Suivant',
        back: 'Retour',
      },
      satisfiedNo: {
        title: `Vous n'êtes pas seul.<br/>Selon nos données, 74 % des personnes <span>{{translate zodiac}}</span> trouvent difficile de communiquer avec leur <span>{{translate zodiac}}</span> partenaire.<br/>Nous pouvons vous aider à améliorer cela.`,
        titleFirstLine: "Vous n'êtes pas seul.",
        titleSecondLine: 'Selon nos données, 74 % des personnes ',
        titleMiddle: ' trouvent difficile de communiquer avec leur ',
        titleEnd: ' partenaire.',
        titleLastLine: 'Nous pouvons vous aider à améliorer cela.',
        next: 'Suivant',
        back: 'Retour',
      },
      emotionalConnection: {
        title: "Êtes-vous d'accord avec l'affirmation ci-dessous ?",
        subtitle:
          '<strong>“Renforcer notre connexion émotionnelle est une priorité pour mon partenaire et moi”</strong>',
        answers: {
          stronglyAgree: "Fortement d'accord",
          agree: "D'accord",
          neutral: 'Neutre',
          disagree: "Pas d'accord",
          stronglyDisagree: 'Fortement désaccord',
        },
      },
      strengthen: {
        title:
          'Nebula aide à <b> renforcer votre ' +
          '</br>' +
          'relation</b> et <b>améliorer votre ' +
          '</br>' +
          'vie amoureuse</b>*',
        tooltip: 'Enquête avec 1126 répondants, menée en juin 2021',
        text: "* 75% de nos utilisateurs ont déclaré que l'astrologie les a aidés à améliorer leur relation",
        next: 'Suivant',
      },
      bigPicture: {
        title:
          'Décririez-vous votre partenaire comme une personne orientée vers les détails ou une personne qui ' +
          'voit la vie dans son ensemble ?',
        answers: {
          detailOriented: 'Axé sur les détails',
          bigPicture: 'Grand ensemble',
          both: 'Un peu des deux',
        },
      },
      introvertOrExtravert: {
        title: 'Est-ce que votre partenaire est introverti ou extraverti ?',
        answers: { introvert: 'Introverti', extrovert: 'Extraverti', both: 'Un peu des deux' },
      },
      irritated: {
        title: "Est-ce que votre partenaire s'énerve ou s'irrite facilement ?",
        answers: { yes: 'Oui', sometimes: 'Parfois', rarely: 'Rarement', notAtAll: 'Pas du tout' },
      },
      conflict: {
        title: 'Êtes-vous satisfait de la façon dont vous et votre partenaire gérez les conflits ?',
        answers: {
          honest: 'Oui, je préfère être honnête et direct',
          situation: 'Dépend de la situation et de la personne',
          hurt: 'Non, je ne veux pas me blesser ou blesser une autre personne',
          nervous: 'Non, ça me rend nerveux',
        },
      },
      aboutGoals: {
        title: 'Quand vous pensez à vos objectifs relationnels, vous vous sentez... ?',
        answers: {
          partnerRightPlace: 'Optimiste ! Ils sont totalement réalisables, avec un peu de guidance',
          partnerThing: "Prudent. J'ai déjà eu des difficultés, mais je suis plein d'espoir",
          partnerTotallyNormal: 'Je me sens un peu anxieux, honnêtement',
          notSure: 'Pas sûr / Ne sait pas',
        },
      },
      partnerRightPlace: {
        title: `Vous êtes venu au bon endroit,<br/><span>{{translate zodiac}}</span> !`,
        subtitle:
          'Savoir que votre objectif est déjà réalisable est très bénéfique. ' +
          'Continuons pour construire un plan de guidance basé sur votre thème astral.',
        next: 'Suivant',
        back: 'Retour',
      },
      partnerThing: {
        title: `<span>{{translate zodiac}}</span>, voici la chose...<br/>Vous pouvez le faire !`,
        subtitle:
          'Nous commencerons par de petites informations afin que cela ne soit pas trop écrasant. Continuons' +
          'et construisons un plan de guidance basé sur votre plan astrologique.',
        next: 'Suivant',
        back: 'Retour',
      },
      partnerTotallyNormal: {
        title: `C’est tout à fait normal d’avoir des nerfs,<br/><span>{{translate zodiac}}</span>.`,
        subtitle:
          'Mais nous savons que vous pouvez le faire. ' +
          'Continuons pour construire un plan de guidance basé sur votre carte du ciel.',
        next: 'Suivant',
        back: 'Retour',
      },
      appreciated: {
        title: "Êtes-vous d'accord avec l'affirmation ci-dessous ?",
        subtitle: '<strong>“Mon partenaire me fait vraiment me sentir apprécié.”</strong>',
        answers: {
          stronglyAgree: "Tout à fait d'accord",
          agree: "D'accord",
          neutral: 'Neutre',
          disagree: 'Désaccord',
          stronglyDisagree: "Pas du tout d'accord",
        },
      },
      decisions: {
        title: 'Prenez-vous des décisions avec votre esprit ou votre <br /> cœur ?',
        answers: { heart: 'Cœur', head: 'Tête', both: 'Les deux' },
      },
      withHeart: {
        title: `Selon nos données, 49% des <span>{{translate zodiac}}</span> personnes prennent également des décisions avec leur cœur. Ne vous inquiétez pas, nous prendrons cela en compte lors de la création de votre plan de guidance`,
        next: 'Suivant',
        back: 'Retour',
      },
      withHead: {
        title: `Selon nos données, 39% des <span>{{translate zodiac}}</span> personnes prennent également des décisions avec leur tête. Ne vous inquiétez pas, nous prendrons cela en compte lors de la création de votre plan de guidance.`,
        next: 'Suivant',
        back: 'Retour',
      },
      both: {
        title: `Merveilleux !<br/>Selon nos données, seuls les 17% <span>{{translate zodiac}}</span> des personnes les plus haut prennent des décisions avec leur tête et leur cœur. Utiliser les deux en quantité égale est la clé pour se sentir harmonieux dans vos relations.`,
        next: 'Suivant',
        back: 'Retour',
      },
      aboutUs: {
        title: 'Comment avez-vous entendu parler de nous ?',
        answers: {
          poster: 'Affiche ou panneau publicitaire',
          friend: 'Ami ou famille',
          instagram: 'Instagram',
          mail: 'Courrier',
          streaming: 'Streaming TV',
          tv: 'TV',
          radio: 'Radio',
          search: 'Moteur de recherche (Google, Bing, etc.)',
          newspaper: 'Journal ou magazine',
          facebook: 'Facebook',
          review: 'Blog ou recherche internet',
          podcast: 'Podcast',
          influencer: 'Influenceur',
          youtube: 'Youtube',
          pinterest: 'Pinterest',
          other: 'Autre',
        },
      },
      loading: {
        initialText: 'Analyse de votre profil...',
        steps: {
          position: 'Identification des positions planétaires lors de votre naissance...',
          blueprint: 'Création de votre plan astrologique...',
          profile: 'Evaluation de votre personnalité...',
          strength: 'Identification de vos forces et faiblesses...',
          compatibility: 'Analyse de votre compatibilité...',
          relationship: 'Analyse de vos besoins relationnels...',
          guidance: 'Conception de votre plan de guidance...',
          predicting: 'Prédiction des résultats futurs...',
          alone: 'Aimez-vous passer du temps <br /> seul ?',
          adventurous: 'Êtes-vous une personne <br /> aventureuse ?',
          remedies: 'Avez-vous déjà essayé des remèdes/rituels ?',
        },
        question: { yes: 'Oui', no: 'Non' },
        text:
          'Restez assis ! Nous construisons votre plan de guidage parfait en fonction de votre empreinte ' +
          "astrologique unique et de millions de points de données provenant d'utilisateurs de Nebula réussis.",
      },
      email: {
        title: 'Entrez votre email pour voir comment vous pouvez',
        nebula: 'avec Nebula',
        placeholder: 'Votre email',
        error: 'Veuillez entrer une adresse email valide',
        text:
          'Nebula ne partage aucune information personnelle. ' +
          'Nous vous enverrons une copie de votre programme pour un accès pratique.',
        label:
          'Je souhaite recevoir mon rapport et des offres personnalisées' +
          ' et des mises à jour de nebula via cet e-mail.',
        button: 'Continuer',
      },
      emailConfirm: {
        title:
          'Obtenir un accès à votre <b>rapport exclusif</b>, offres spéciales, mises à jour, ' +
          'astrologie et conseils de relation, recettes et cadeaux gratuits.',
        subtitle: 'Recevez tout ! Confirmez la réception des e-mails pour ne rien manquer',
        button: 'Confirmer',
        skip: "Je connais tout sur l'astrologie et les relations",
      },
      onboarding: {
        list: [
          'Bienvenue sur Nebula',
          'En fonction de vos réponses',
          'Nous avons créé votre plan directeur astrologique et votre plan de guidance.',
          'Pour vous aider à mieux vous comprendre',
          'Et pour améliorer votre relation - pour le mieux.',
          'Commençons.',
        ],
        listWithGoal: [
          'Bienvenue sur Nebula',
          'En fonction de vos réponses',
          'Nous avons créé votre plan directeur astrologique et votre plan de guidance.',
          'Pour vous aider à {{goal}}',
          'Et pour améliorer votre relation - pour le mieux.',
          'Commençons.',
        ],
      },
      salesScreen: {
        title: 'Votre clarté personnalisée & Love Reading est prêt!',
        fixedHeader: {
          title: `La remise expire`,
          minutes: 'min',
          seconds: 'sec',
          button: 'obtenir ma lecture',
        },
        userDetails: {
          youImageText: 'Vous',
          partnerImageText: 'Partenaire',
          zodiac: 'Signe du zodiaque',
          gender: 'Genre',
          dateOfBirth: 'Date de naissance',
          placeOfBirth: 'Lieu de naissance',
          female: 'Femme',
          male: 'Homme',
        },
        sectionGoal: {
          title: 'Le but à atteindre',
          'Find my perfect partner': 'Trouver le partenaire idéal',
          'Understand myself better': 'Mieux me comprendre',
          'To get married': 'Se marier',
          'Achieve happiness': 'Atteindre le bonheur',
          'Personal growth': 'Croissance personnelle',
          'Check compatibility': 'Vérifier la compatibilité',
          'Increase relationship satisfaction': 'Augmenter la satisfaction de votre relation',
          'Fix relationship problems': 'Résoudre les problèmes de relation',
          'Build a strong marriage': 'Construire un mariage solide',
          'All above': "Trouver l'amour et mieux me comprendre",
          'All above in relationships': 'Améliorer les relations',
        },
        specialOffer: {
          header: 'Offre spéciale',
          trialTitle: 'Lecture personnalisée pour ',
          total: "Total aujourd'hui :",
          discount: 'La remise expire',
          saveMoney: 'Economisez {{discount}} par période',
          perWeeksCostOld: "Votre coût par 30 jours <bold>après l'essai</bold>",
          perWeeksCost: "Y compris 1 semaine d'essai de lecture personnelle",
          optionTitle: 'Choisissez une option:',
          expensiveOption:
            "Soutenir ceux qui ont besoin de choisir le prix d'essai le plus bas en choisissant cette option",
          getAccess: 'obtenir ma lecture',
        },
        guaranteeText: 'Paiements de sécurité garantis',
        blurredReport: {
          title: 'Votre rapport',
          zodiac: 'Signe du zodiaque',
          gender: 'Genre',
          contentTitle: 'Contenu',
          points: [
            "Compatibilité avec votre partenaire dans d'autres domaines de votre vie.",
            'Une analyse approfondie des relations avec votre partenaire, basée sur un ' +
              'système unique de correspondance des cartes de naissance.',
            'Un guide simple et pratique pour améliorer votre relation avec votre partenaire.',
            'Avertissement sur les événements astrologiques et conseils pratiques qui vous ' +
              'aideront à bien traverser cette période.',
            'Votre horoscope et les événements à venir en 2023',
            'Vos forces et faiblesses uniques et comment en tirer le meilleur parti.',
          ],
          personalityTitle: 'Personnalité',
          personalityContent:
            "Le Verseau est le 11e signe du zodiaque et représente l'inventivité " +
            'et la brillance. Les planètes dominantes sont Uranus et Saturne, ce qui confère à ce ' +
            'signe une personnalité à la fois restreinte et libérée. Saturne est associé aux limites ' +
            "et aux frontières, tandis qu'Uranus est le symbole de la liberté.",
          accessDescription: 'Pour lire le rapport complet vous devez obtenir aceess',
          getAccess: 'obtenir ma lecture',
        },
        userReviews: {
          title: 'Les usagers nous aiment',
          list: {
            user1: {
              review:
                `Il m'a été très utile et m'a apporté la clarté dont j'avais besoin dans la ` +
                `situation actuelle de ma relation. Cela me donne l'espoir que ma relation peut ` +
                `encore être sauvée. Je vous remercie. Hautement recommandé !`,
            },
            user2: {
              review:
                "Incroyable, absolument incroyable ! Les affirmations que j'ai reçues et les conseils que " +
                "j'ai reçus valaient tout l'or du monde ! Vraiment, merci !",
            },
            user3: {
              review:
                "Il m'aide à avoir confiance en moi et en mes choix pour l'avenir en me rassurant " +
                'grâce aux informations que je reçois. Mes objectifs et mes rêves vont se réaliser ' +
                "et maintenant je me fais confiance pour faire ce dont j'ai besoin et ce que je souhaite.",
            },
          },
        },
        benefits: {
          title: 'Ce que vous obtenez',
          points: [
            'Votre plan personnalisé',
            '1:1 conseils de votre propre astrologue',
            'Trouver un partenaire compatible',
            'Des informations sur vos schémas relationnels et sur vos besoins émotionnels et sexuels',
            'Meilleure compréhension de soi',
          ],
        },
        featuredInTitle: 'Tel que présenté dans',
        QASection: {
          title: 'On demande souvent',
          question1: 'Quelle est la précision de la lecture astrologique sur cette plateforme ?',
          answer1:
            "L'exactitude d'une lecture astrologique, bien que subjective et inexacte, " +
            'peut offrir des éclairages utiles. Notre plateforme, combinant algorithmes ' +
            'avancés et astrologues experts, vise à maximiser cette précision.',
          question2: 'Puis-je obtenir une lecture de compatibilité pour les relations ?',
          answer2:
            "Oui, il est possible d'obtenir une lecture de compatibilité pour les relations. " +
            'Ce type de lecture comprend une analyse astrologique complète de deux personnes ' +
            "afin d'évaluer leur compatibilité dans divers domaines, notamment l'amour, la " +
            'communication et les valeurs partagées.',
          question3:
            'Les lectures astrologiques sur cette plateforme sont-elles confidentielles et privées ?',
          answer3:
            'Oui, toutes nos lectures sont strictement confidentielles. Nous préservons la ' +
            'confidentialité des utilisateurs, sécurisons leurs données et ne les partageons ' +
            'jamais sans consentement.',
        },
        charge: {
          trial:
            'Vous serez facturé seulement <bold>{{trialPrice}} pour votre essai de 7</bold> jours. ' +
            "Nous vous enverrons un rappel par <bold>e-mail</bold> avant la fin de votre période d'essai. " +
            '<bold>Annulez à tout moment.</bold>',
          europeanTrial:
            'Vous serez facturé seulement <bold>{{europeanTrialPrice}} pour ' +
            'votre essai de 7</bold> jours. Nous vous enverrons un rappel par <bold>e-mail</bold> ' +
            "avant la fin de votre période d'essai. <bold>Annulez à tout moment.</bold>",
          trialUSStart: 'Vous souscrivez à un abonnement mensuel au service ',
          trialUSEnd:
            ". En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 jours pour le {{trialPrice}}, vous serez automatiquement facturé {{fullPrice}} tous les 2 semaines jusqu'à ce que vous annuliez dans les paramètres. Les frais apparaîtront sur votre facture sous le nom de {{domainUrl}}. Pour en savoir plus sur la politique d'annulation et de remboursement, consultez la section",
          trialUS:
            "Vous vous inscrivez à l'abonnement mensuel au service NEBULA. En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 jours pour le {{trialPrice}}, vous serez automatiquement facturé {{fullPrice}} tous les 30 jours jusqu'à ce que vous annuliez dans les paramètres. Les frais apparaîtront sur votre facture sous le nom de {{domainUrl}}. Pour en savoir plus sur la politique d'annulation et de remboursement, consultez la section ",
        },
        chargeTest:
          'Vous serez facturé seulement <bold>{{price}} pour ' +
          'votre essai de 7</bold> jours. Nous vous enverrons un rappel par <bold>e-mail</bold> ' +
          "avant la fin de votre période d'essai. <bold>Annulez à tout moment.</bold>",
        description: {
          text:
            'Vous souscrivez un abonnement de 2 semaines au NEBULA service. ' +
            "En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 " +
            'jours pour <bold>{{trialPrice}}</bold>, ' +
            'vous serez automatiquement facturé <bold>{{plan}}</bold> ' +
            "toutes les 2 semaines jusqu'à ce que vous annuliez dans les paramètres. " +
            "Pour en savoir plus sur la politique d'annulation et de remboursement, consultez la ",
          textEU:
            'Vous souscrivez un abonnement de 2 semaines au NEBULA service. ' +
            "En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 " +
            'jours pour <bold>{{europeanTrialPrice}}</bold>, ' +
            'vous serez automatiquement facturé <bold>{{plan}}</bold> ' +
            "toutes les 2 semaines jusqu'à ce que vous annuliez dans les paramètres. " +
            "Pour en savoir plus sur la politique d'annulation et de remboursement, consultez la ",
          terms: "Politique d'abonnement",
          termsEU: "Politique d'abonnement",
          termsExperiment: "consultez les conditions d'abonnement",
        },
        trialChoiceDescription: {
          header:
            'Nous avons aidé des millions de personnes à avoir une vie plus heureuse et de meilleures relations, et ' +
            'nous voulons vous aider aussi.',
          money:
            "L'argent ne devrait pas se mettre en travers de la recherche d'une guidance astrologique qui fonctionne " +
            'enfin. Alors, choisissez un montant que vous jugez raisonnable pour nous essayer pendant une semaine.',
          paymentDescription:
            'Il nous en coûte {{priceForUs}} pour offrir un essai de 7 jours, mais veuillez choisir le montant ' +
            "avec lequel vous êtes à l'aise.",
        },
      },
      trialChoice: {
        header:
          'Nous avons aidé des millions de personnes à avoir une vie plus heureuse et de meilleures relations, et nous voulons vous aider aussi.',
        money:
          "L'argent ne devrait pas se mettre en travers de la recherche d'une guidance astrologique qui fonctionne " +
          'enfin. Alors, choisissez un montant que vous jugez raisonnable pour nous essayer pendant une semaine.',
        paymentDescription:
          'Il nous en coûte {{priceForUs}} pour offrir un essai de 7 jours, mais veuillez choisir le montant ' +
          "avec lequel vous êtes à l'aise.",
        expensiveOption:
          "Cette option nous aidera à soutenir ceux qui doivent sélectionner les prix d'essai les plus bas",
        button: 'Voir mon plan',
        note: "*Coût de l'essai à partir du 24 février 2023",
        information: {
          title: "personnes se sont inscrites aujourd'hui",
          list: [
            'talen**** <b>a choisi {{ middlePrice}}</b>',
            'janin**** <b>a choisi {{ middlePrice}}</b>',
            'peter**** <b>a choisi {{ middlePrice}}</b>',
            'edwar**** <b>a choisi {{ lowerPrice}}</b>',
            'chiar**** <b>a choisi {{ middlePrice}}</b>',
            'anton**** <b>a choisi {{ lowerPrice}}</b>',
            'heath**** <b>a choisi {{ middlePrice}}</b>',
            'lucas**** <b>a choisi {{ lowerPrice}}</b>',
            'tamar**** <b>a choisi {{ lowerPrice}}</b>',
            'izzy_**** <b>a choisi {{ greaterPricePlan }}</b>',
            'coryz**** <b>a choisi {{ highPricePlan}}</b>',
            'diann**** <b>a choisi {{ middlePrice}}</b>',
            'gofor**** <b>a choisi {{ lowerPrice}}</b>',
            'benne**** <b>a choisi {{ middlePrice}}</b>',
            'matth**** <b>a choisi {{ middlePrice}}</b>',
            'Olive**** <b>a choisi {{ highPricePlan}}</b>',
            'teres**** <b>a choisi {{ middlePrice}}</b>',
            'tommy**** <b>a choisi {{ lowerPrice}}</b>',
            'betha**** <b>a choisi {{ greaterPricePlan }}</b>',
            'yukin**** <b>a choisi {{ middlePrice}}</b>',
            'ilove**** <b>a choisi {{ greaterPricePlan }}</b>',
            'fitzg**** <b>a choisi {{ lowerPrice}}</b>',
            'r_mel**** <b>a choisi {{ middlePrice}}</b>',
            'clare**** <b>a choisi {{ greaterPricePlan }}</b>',
            'elize**** <b>a choisi {{ lowerPrice}}</b>',
            'carlo**** <b>a choisi {{ greaterPricePlan }}</b>',
            'lucil**** <b>a choisi {{ lowerPrice}}</b>',
            'ellen**** <b>a choisi {{ highPricePlan}}</b>',
            'carli**** <b>a choisi {{ middlePrice}}</b>',
            'rebec**** <b>a choisi {{ greaterPricePlan }}</b>',
            'cinth**** <b>a choisi {{ greaterPricePlan }}</b>',
            'flavi**** <b>a choisi {{ lowerPrice}}</b>',
            'awint**** <b>a choisi {{ middlePrice}}</b>',
            'linda**** <b>a choisi {{ lowerPrice}}</b>',
            'jazzy**** <b>a choisi {{ lowerPrice}}</b>',
            'claud**** <b>a choisi {{ lowerPrice}}</b>',
            'katja**** <b>a choisi {{ highPricePlan}}</b>',
            'james**** <b>a choisi {{ middlePrice}}</b>',
            'krist**** <b>a choisi {{ lowerPrice}}</b>',
            'alice**** <b>a choisi {{ lowerPrice}}</b>',
            'johnr**** <b>a choisi {{ lowerPrice}}</b>',
            'lucyl**** <b>a choisi {{ lowerPrice}}</b>',
            'hanna**** <b>a choisi {{ greaterPricePlan }}</b>',
            'dariu**** <b>a choisi {{ highPricePlan}}</b>',
            'jean.**** <b>a choisi {{ middlePrice}}</b>',
            'palom**** <b>a choisi {{ highPricePlan}}</b>',
            'oweng**** <b>a choisi {{ lowerPrice}}</b>',
            'aless**** <b>a choisi {{ lowerPrice}}</b>',
            'nadia**** <b>a choisi {{ lowerPrice}}</b>',
            'agnes**** <b>a choisi {{ lowerPrice}}</b>',
          ],
        },
      },
      productOverview: {
        banner:
          "Obtenez 30% de réduction + 7 jours d'essai avec le code <highlight>Nebula22</highlight>",
        title: 'Salut, <zodiac>{{translate zodiac}}</zodiac> Soleil,',
        subtitle:
          'Sur la base de vos réponses et de votre thème astral, ' +
          '<highlight>nous avons préparé votre plan personnalisé.</highlight>',
        includedTitle: 'Points forts de votre plan :',
        list: {
          advice: 'Des conseils au top de votre astrologue personnel',
          finding: 'Trouvez le partenaire le plus compatible',
          improving: 'Améliorez votre relation avec votre partenaire',
          patterns: 'Découvrez vos schémas relationnels, et vos besoins émotionnels et sexuels',
          betterUnderstanding: 'Comprenez-vous mieux',
          loved: 'ainsi que votre être aimé',
        },
        reviewsTitle: 'Avis des clients :',
        featuredTitle: 'Comme présenté dans :',
        button: 'Continuer',
      },
      trialPayment: {
        specialOffer: 'Offre spéciale',
        title: 'Commencez votre essai de 7 jours',
        subtitle: {
          text: 'Annulez à tout moment.',
          noPressure: 'Pas de pression.',
          other: "Pas d'engagement. ",
        },
        total: "Total aujourd'hui :",
        readingTitle: 'Obtenez votre lecture',
        readingSubtitle: 'Personnalisé juste pour vous.',
        specialCode: 'Code NEBULA22 appliqué !',
        timer: 'Réservé pour',
        price: {
          text: 'Votre coût bi-hebdomadaire ',
          trial: "après l'essai",
          save: 'Économisez ',
          readingTitle: 'Votre prix pour la lecture :',
          period: 'chaque période',
        },
        information: {
          title: 'personnes ont appris des choses sur les relations au cours de la dernière heure',
          list: [
            'wesle**** <b>ont eu 7 conseils</b>',
            'karol**** <b>ont eu 2 conseils</b>',
            'david**** <b>ont eu 9 conseils</b>',
            'craig**** <b>ont eu 1 conseils</b>',
            'brian**** <b>ont eu 8 conseils</b>',
            'joann**** <b>ont eu 5 conseils</b>',
            'grego**** <b>ont eu 6 conseils</b>',
            'amyma**** <b>ont eu 6 conseils</b>',
            'sandy**** <b>ont eu 1 conseils</b>',
            'chloe**** <b>ont eu 9 conseils</b>',
            'rache**** <b>ont eu 9 conseils</b>',
            'darre**** <b>ont eu 4 conseils</b>',
            'karin**** <b>ont eu 1 conseils</b>',
            'miche**** <b>ont eu 12 conseils</b>',
            'georg**** <b>ont eu 8 conseils</b>',
            'hanna**** <b>ont eu 4 conseils</b>',
            'erica**** <b>ont eu 3 conseils</b>',
            'kelly**** <b>ont eu 8 conseils</b>',
            'jessi**** <b>ont eu 6 conseils</b>',
            'geral**** <b>ont eu 8 conseils</b>',
            'patri**** <b>ont eu 1 conseils</b>',
            'carlo**** <b>ont eu 2 conseils</b>',
            'melan**** <b>ont eu 5 conseils</b>',
            'gordo**** <b>ont eu 6 conseils</b>',
            'marye**** <b>ont eu 12 conseils</b>',
            'calvi**** <b>ont eu 7 conseils</b>',
            'rebec**** <b>ont eu 7 conseils</b>',
            'yolan**** <b>ont eu 10 conseils</b>',
            'simon**** <b>ont eu 8 conseils</b>',
            'maggi**** <b>ont eu 10 conseils</b>',
            'jade.**** <b>ont eu 1 conseils</b>',
            'court**** <b>ont eu 7 conseils</b>',
            'jesse**** <b>ont eu 6 conseils</b>',
            'tammy**** <b>ont eu 2 conseils</b>',
            'felic**** <b>ont eu 11 conseils</b>',
            'ivan.**** <b>ont eu 10 conseils</b>',
            'carol**** <b>ont eu 2 conseils</b>',
            'karen**** <b>ont eu 9 conseils</b>',
            'trish**** <b>ont eu 6 conseils</b>',
            'pamel**** <b>ont eu 9 conseils</b>',
            'marks**** <b>ont eu 2 conseils</b>',
            'brend**** <b>ont eu 9 conseils</b>',
            'lucyl**** <b>ont eu 6 conseils</b>',
            'hanna**** <b>ont eu 9 conseils</b>',
            'dariu**** <b>ont eu 11 conseils</b>',
            'jean.**** <b>ont eu 12 conseils</b>',
            'palom**** <b>ont eu 9 conseils</b>',
            'oweng**** <b>ont eu 4 conseils</b>',
            'aless**** <b>ont eu 8 conseils</b>',
            'nadia**** <b>ont eu 5 conseils</b>',
          ],
        },
        charge: {
          trial:
            'Vous ne serez facturé que de <bold>{{trialPrice}} pour votre essai de 7 jours.</bold>' +
            "<br/>Nous vous <bold>enverrons un rappel par e-mail</bold> avant la fin de votre période d'essai. " +
            '<bold>Annulez à tout moment.</bold>',
          europeanTrial:
            'Vous ne serez facturé que de <bold>{{europeanTrialPrice}}</bold> ' +
            'pour votre essai de 7 jours.<br/> Nous vous <bold>enverrons un rappel par e-mail</bold> ' +
            "avant la fin de votre période d'essai. <bold>Annulez à tout moment.</bold>",
          email:
            "Nous vous enverrons un rappel par email avant la fin de votre période d'essai. " +
            '<bold>Annulez à tout moment.</bold>',
          lecture: 'Vous ne serez facturé que <bold>{{trialPrice}}</bold> pour votre lecture.',
        },
        pay: 'Accéder',
        description: {
          text:
            "Étendu bihebdomadaire après une période d'essai d'une semaine au prix de {{plan}}. " +
            'Les taxes sont calculées à la caisse.',
          textUS:
            "En continuant, vous acceptez que si vous ne annulez pas avant la fin de l'essai de 7 jours, " +
            "vous serez automatiquement facturé <bold>{{plan}} toutes les 2 semaines </bold> jusqu'à" +
            "ce que vous annuliez dans les paramètres. En savoir plus sur la politique d'annulation et de " +
            'remboursement dans ',
          terms: "Politique d'abonnement",
        },
        address: `${config.companyName}, Athalassas, 62, MEZZANINE, Strovolos, 2012, Nicosie, Chypre`,
      },
      payment: {
        title: 'Choisir la méthode de paiement',
        divider: 'ou',
        form: {
          default: {
            submitButtonText: 'DÉMARRER ESSAI DE 7 JOURS',
            charged: 'Vous ne serez facturé que {{price}} pour votre essai de 7 jours.',
          },
          resign: {
            submitButtonText: 'Acheter',
          },
        },
        charge: {
          trial:
            'Vous ne serez facturé que de <bold>{{price}} pour votre essai de 7 jours.</bold><br/>' +
            "Nous vous <bold>enverrons un rappel par e-mail</bold> avant la fin de votre période d'essai. " +
            'Annuler à tout moment.',
          trialUS:
            "Vous ne serez facturé <bold>{{price}}} que pour votre période d'essai de 7 jours." +
            "Nous vous enverrons un email de rappel</bold> avant la fin de votre période d'essai. " +
            'Annulez à tout moment. Les frais apparaîtront sur votre facture sous le nom de {{domainUrl}}.',
          full: 'Vous ne serez facturé que <bold>{{price}} pour votre essai de 7 jours.</bold>',
          email:
            "Nous vous <bold>enverrons un rappel par e-mail</bold> avant la fin de votre période d'essai. " +
            '<bold>Annuler à tout moment.</bold>',
        },
        pay: {
          trial: 'Votre <bold>essai de 7 jours</bold>',
          price: 'Seulement',
          tax: 'TVA',
          total: 'Total',
        },
        resources: 'Paiements sécurisés',
      },
      additionalDiscount: {
        title: 'Économisez 65% !',
        plan: '65% de réduction sur votre plan personnalisé',
        trial: 'Essai de 7 jours',
        price: 'au lieu de',
        button: 'Obtenez une réduction secrète !',
      },
      trialPaymentWithDiscount: {
        offer: 'RABAIS SECRET <bold>65% de réduction</bold> appliqué',
        title: "Vous bénéficiez d'une réduction secrète !",
        pay: 'Commencez votre essai de 7 jours',
        subtitle: 'Pas de pression. Annulez à tout moment.',
        specialCode: 'Rabais secret appliqué !',
        price: "Votre coût par 2 semaines après l'essai:",
        priceExperiment: "Votre coût par 30 jours après l'essai:",
        saveMoney: 'Vous économisez {{savedMoney}}',
        saveMoneyExperiment: 'Vous économisez {{savedMoney}}',
        total: "Total aujourd'hui :",
        description: `En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 jours, vous serez automatiquement facturé {{ discountPrice }} pour la période d'introduction de 2 semaines, puis au taux standard de {{ fullPrice }} tous les 2 semaines jusqu'à ce que vous annuliez dans les paramètres. Pour en savoir plus sur la politique d'annulation et de remboursement, voir`,
        descriptionExperiment: `En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 jours, vous serez automatiquement facturé {{ discountPrice }} pour la période d'introduction de 30 jours, puis au taux standard de {{ fullPrice }} tous les 30 jours jusqu'à ce que vous annuliez dans les paramètres. Pour en savoir plus sur la politique d'annulation et de remboursement, voir`,
        terms: 'Conditions de souscription',
      },
      unlimitedReadings: {
        title: "Accédez aux guides d'astrologie et aux lectures de compatibilité illimités",
        contains: {
          affirmations: 'affirmations personnelles pour tous les jours',
          moreInsights: "plus d'informations en moins de temps",
          learnVisually: 'apprendre visuellement',
          minutesToRead: 'minutes de lecture',
        },
        benefits: {
          unlimitedCompatibilityChecks: 'vérifier la compatibilité autant de fois que nécessaire',
          manyInsight:
            "des centaines d'informations pour vous aider à atteindre vos objectifs relationnels",
          dailyHoroscopes:
            'horoscope quotidien personnalisé et guides astrologiques sans publicité',
          autoRenewable: "plan d'un mois auto-renouvelable, résiliation à tout moment",
        },
        button: 'Ajouter un nombre illimité de lectures',
        skip: 'Ignorer cette offre et poursuivre la procédure',
        disclaimer:
          "Remarque: En plus de votre abonnement, votre compte sera débité {{price}} pour les compléments sélectionnés lorsque vous cliquez sur Ajouter des lectures illimitées. Les articles figurant sur cette page sont des abonnements {{period}}. À moins que vous ne l'annuliez dans votre profil avant la fin de la période en cours, vous acceptez que l'abonnement soit renouvelé automatiquement à la fin de chaque période. Si vous avez besoin d'aide pour résilier votre abonnement, consultez notre section ",
      },
      upsells: {
        header: {
          step1: 'Acheter le rapport',
          step2: 'Consultations',
          step3: 'Accéder au produit',
          banner: {
            title: 'Merci !',
            text: 'Votre commande a été réussie !',
          },
          title: "Choisissez votre offre d'inscription",
          subtitle: 'Disponible seulement maintenant',
        },
        headerNew: {
          addReport: 'Ajouter un rapport',
          unlimitedReadings: 'Lectures illimitées',
          addConsultation: 'Ajouter une consultation',
          accessProduct: 'Accès au produit',
        },
        footer: {
          description:
            "*Vous serez facturé pour les services ou produits supplémentaires sélectionnés au moment de l'achat." +
            "Ce n'est pas un paiement récurrent.",
        },
        button: 'Obtenir ma copie',
        link: 'Ignorer cette offre et continuer',
      },
      trialCredits: {
        header: {
          step1: 'Acheter le rapport',
          step2: 'Consultations',
          step3: 'Accéder au produit',
          title: 'Plus pour vous',
          subtitle:
            'Offre exclusive recommandée pour vous pour atteindre vos objectifs plus rapidement',
        },
        payment: {
          title: 'Votre consultation individuelle unique',
          consultation:
            '<highlight>30 minutes privées </highlight><br />' +
            '<highlight>consultation avec un expert</highlight>',
          description:
            "Vous pouvez demander une prédiction de l'avenir, une analyse de compatibilité en direct; " +
            'compatibilité amoureuse, etc.',
          offerPrice: 'Offre de prix unique :',
          originalPrice: {
            price: 'Prix ​​original :',
            discount: 'Économisez',
          },
        },
        footer: {
          description:
            "*Vous serez facturé pour les services ou produits supplémentaires sélectionnés au moment de l'achat." +
            "<br /> Il s'agit d'un paiement non récurrent.",
        },
        button: 'Obtenir ma consultation',
        link: 'Ignorer cette offre et continuer',
      },
      onboardingLP: {
        welcome: 'Bienvenue sur',
        title: 'AskNebula',
        text:
          'Consultations et lectures hyper <highlight>personnalisées</highlight> ' +
          '<highlight>avec les meilleurs conseillers</highlight>',
      },
      talkWithExpert: {
        text:
          'En tant que partie de votre plan de guidance, vous devez parler avec le conseiller psychique à ' +
          'propos de <br /><highlight>{{userAnswer}}</highlight>',
        single: {
          perfectPartner: 'trouver votre partenaire parfait',
          understandBetter: 'vous comprendre mieux',
          loadingBoth: 'trouver votre partenaire parfait et vous comprendre mieux',
        },
        relationship: {
          fixProblems: 'résoudre les problèmes de relation',
          relationshipSatisfaction: 'augmenter la satisfaction de la relation',
          both: 'résoudre les problèmes de relation et augmenter la satisfaction de la relation',
        },
      },
      questions: {
        title: "Les questions de nos utilisateurs'",
        tip: '* basé sur un sondage et avec le consentement des participants',
        firstMessage: {
          single: "Est-ce que mon ex m'aime encore ?",
          notSingle: 'Est-ce que mon partenaire me trompe ?',
        },
        secondMessageSingle: {
          understandBetter: 'Quel est mon but divin dans la vie ?',
          default: 'Quand vais-je me marier  ?',
        },
        secondMessageRelationship: {
          relationshipSatisfaction: 'Suis-je avec mon âme sœur ?',
          default: 'Pourquoi mon partenaire est-il si distant ?',
        },
        thirdMessageSingle: {
          understandBetter: 'Que dois-je faire pour mieux me comprendre ?',
          default: 'Que dois-je faire pour trouver mon partenaire parfait ?',
        },
        thirdMessageRelationship: {
          relationshipSatisfaction:
            'Que dois-je faire pour améliorer la satisfaction de la relation ?',
          default: 'Que dois-je faire pour résoudre mes problèmes de relation ?',
        },
      },
      credits: {
        text:
          'Votre plan comprend 150 crédits. <highlight> Vous recevrez ' +
          '{{creditsAmount}} crédits supplémentaires toutes les 30 jours pour discuter de la progression avec ' +
          'votre conseiller </highlight>',
      },
      loadingLP: {
        title:
          'Hourra ! <highlight>Nous vous mettons en correspondance</highlight> avec <highlight>le ' +
          'bon conseiller</highlight>',
        list: [
          'Analyse de vos réponses...',
          "Récupération d'une liste de conseillers disponibles...",
          'Vous associer aux meilleurs conseillers...',
          'Personnalisation de votre expérience...',
        ],
      },
      additionalQuestionOne: {
        title: 'Voulez-vous en obtenir encore plus ?',
        text: "Discutez de votre relation et d'autres problèmes de vie avec un conseiller psychique !",
        redirectButton: 'Trouver un conseiller',
        continueButton: 'Je ne suis pas intéressé',
      },
      additionalQuestionTwo: {
        title:
          'Êtes-vous sûr de ne pas vouloir trouver des réponses qui affectent la qualité de votre vie ?',
        text:
          "AskNebula a d'excellents conseillers psychiques qui " +
          "peuvent fournir des lectures <bold>précises sur l'amour et les relations, la vie et d'autres sujets</bold>",
        redirectButton: 'Je ne veux pas de réponses',
        continueButton: 'Je veux trouver des réponses',
      },
    },
    'intro-offer': {
      credits: {
        text:
          'Votre plan comprend {{creditsAmount}} crédits. <highlight> Vous recevrez ' +
          '300 crédits supplémentaires toutes les 2 semaines pour discuter de la progression avec ' +
          'votre conseiller </highlight>',
      },
    },
    'natal-chart': {
      progressBar: 'Prenez le contrôle de votre vie',
      billingScreen: {
        description:
          "En continuant, vous acceptez d'être automatiquement facturé {{price}} chaque mois jusqu'à ce que vous annuliez dans les paramètres. Pour en savoir plus sur la politique d'annulation et de remboursement ",
        trialDescription:
          "Vous souscrivez à un <bold>abonnement de 7 jours</bold> au service NEBULA. En continuant, vous acceptez que si vous n'annulez pas avant la fin de la période d'essai de 7 jours pour {{trialPrice}}</bold>, vous serez automatiquement facturé {{price}} tous les mois jusqu'à ce que vous annuliez dans les paramètres. Pour en savoir plus sur la politique d'annulation et de remboursement",
        terms: "Conditions d'abonnement.",
      },
    },
    common: {
      aquarius: 'verseau',
      aries: 'bélier',
      cancer: 'cancer',
      capricorn: 'capricorne',
      gemini: 'gemeaux',
      leo: 'lion',
      libra: 'balance',
      pisces: 'poissons',
      sagitarius: 'sagittaire',
      scorpio: 'scorpion',
      taurus: 'taureau',
      virgo: 'vierge',
      male: 'homme',
      female: 'femelle',
    },
    reviewsList: {
      user1: {
        name: '@ria._.panwar',
        date: '09/17/2021',
        text:
          "L'horoscope de Nebula raconte des faits réalistes sur la vie quotidienne, qui peuvent facilement " +
          'être reliés. Il montre la direction à suivre.',
      },
      user2: {
        name: '@jp63_',
        date: '09/29/2021',
        text: 'Ça me fait me sentir en sécurité, bien, heureux et intelligent.',
      },
      user3: {
        name: '@therealslimmazi',
        date: '09/28/2021',
        text: `J'aime que nous ayons l'option "mon profil". J'aime apprendre à me connaître.`,
      },
    },
    ultimatePackages: {
      week1: {
        title: 'Semaine 1 :',
        text: 'Analyse de numérologie',
      },
      week2: {
        title: 'Semaine 2 :',
        text: 'Lecture de tarot',
      },
      week3: {
        title: 'Semaine 3 :',
        text: 'Guide de chiromancie',
      },
      'week4-6': {
        title: 'Semaines 4-6 :',
        text: 'Lectures des anges',
      },
      'week7-10': {
        title: 'Semaines 7-10 :',
        text: 'Matrice du destin et prédiction de la richesse',
      },
    },
    regularPackages: {
      week1: {
        title: 'Semaine 1 :',
        text: 'Analyse numérologique',
      },
      week2: {
        title: 'Semaine 2 :',
        text: 'Lecture de tarot',
      },
      week3: {
        title: 'Semaine 3 :',
        text: 'Guide de chiromancie',
      },
    },
    plans: {
      numerologyAnalysis: {
        oldPriceDescription: 'était',
        discount: '50% OFF',
        title: 'ANALYSE NUMÉROLOGIQUE',
      },
      tarotReading: {
        oldPriceDescription: 'était',
        discount: '45% OFF',
        title: 'LECTURE DE TAROT',
      },
      palmistryGuide: {
        oldPriceDescription: 'était',
        discount: '30% OFF',
        title: 'GUIDE DE PALMISTRY',
      },
      ultraPack: {
        oldPriceDescription: 'prix régulier',
        discount: '50% OFF',
        description: '(3 en 1 + 2 lectures bonus secrètes)',
        title: 'ULTRA PACK',
      },
    },
    progressHeader: {
      step1: {
        top: 'Acheter',
        bottom: 'rapport',
      },
      step2: {
        top: 'Ajouter',
        bottom: 'lectures',
      },
      step3: {
        top: 'Accéder à',
        bottom: 'produit',
      },
    },
    progressBarTitle: {
      profile: 'Votre profil',
      personality: 'Traits de personnalité',
      relationship: 'Modèle de relation',
      partner: 'Profil de votre partenaire',
      personalityPatterns: 'Modèles de relation et de personnalité',
    },
    agreement: {
      text: "En continuant, j'accepte",
      policy: 'La politique de confidentialité',
      separator: 'et',
      terms: "Les conditions d'utilisation.",
    },
    paymentFailed: {
      title: 'Paiement échoué',
      text: "Votre transaction a échoué en raison d'une erreur technique. Veuillez réessayer.",
      button: 'Réessayer',
    },
    zodiac: {
      aquarius: 'verseau',
      aries: 'bélier',
      cancer: 'cancer',
      capricorn: 'capricorne',
      gemini: 'gemeaux',
      leo: 'lion',
      libra: 'balance',
      pisces: 'poissons',
      sagitarius: 'sagittaire',
      scorpio: 'scorpion',
      taurus: 'taureau',
      virgo: 'vierge',
    },
    zodiacDescription: {
      aquarius: {
        male: "Révolutionnaire dans sa pensée, l'homme Verseau brise les barrières, imaginant un lendemain plus lumineux et non conventionnel.",
        female:
          "La femme Verseau, avec son esprit d'avant-garde, danse à son propre rythme, défendant toujours l'innovation.",
      },
      aries: {
        male: "L'homme Bélier avance avec une énergie inégalée, toujours prêt à conquérir de nouveaux horizons.",
        female:
          "Rayonnante de confiance, la femme Bélier mène souvent le groupe, animée par l'ambition et la détermination.",
      },
      cancer: {
        male: "Profondément intuitif, l'homme Cancer garde son domaine émotionnel, puisant sa force dans les liens familiaux.",
        female:
          "L'esprit bienveillant d'une femme Cancer crée un cocon de confort pour ses proches.",
      },
      capricorn: {
        male: "Avec la ténacité d'une chèvre de montagne, l'homme Capricorne grimpe les sommets de la vie avec une dédication disciplinée.",
        female:
          'Réaliste et sage, la femme Capricorne est un pilier de résilience, fusionnant ambition et but.',
      },
      gemini: {
        male: "Toujours curieux, l'homme Gémeaux est un tourbillon d'idées, changeant souvent de sujets avec enthousiasme.",
        female:
          "Pétillante d'esprit, la femme Gémeaux charme par sa polyvalence, s'adaptant constamment au changement.",
      },
      leo: {
        male: "Avec sa prestance royale, l'homme Lion possède un charisme magnétique qui exige le feu des projecteurs.",
        female:
          'Vibrante et confiante, la femme Lion rayonne de chaleur, régnant sur son domaine avec générosité et grâce.',
      },
      libra: {
        male: "Guidé par l'harmonie, l'homme Balance équilibre avec grâce les défis de la vie, cherchant toujours le juste milieu.",
        female:
          "Charme personnifié, la femme Balance est l'incarnation de l'élégance, maniant la diplomatie avec une touche artistique.",
      },
      pisces: {
        male: "Rêveur et empathique, l'homme Poissons navigue dans les sphères de l'émotion, exprimant souvent son âme à travers l'art.",
        female:
          'Éthérée et compatissante, la femme Poissons ressent profondément, tissant des contes de romance et de magie sur son passage.',
      },
      sagittarius: {
        male: "Avec la soif de voyager dans son cœur, l'homme Sagittaire poursuit la connaissance et l'aventure, toujours l'éternel optimiste.",
        female:
          "Vivace et libre d'esprit, la femme Sagittaire traverse la vie, répandant joie et enthousiasme contagieux.",
      },
      scorpio: {
        male: "L'homme Scorpion plonge profondément, avec une intensité qui peut dévoiler les mystères de la vie, poussé par la passion et la détermination.",
        female:
          "L'énigmatique femme Scorpion possède un charme magnétique, ses profondeurs voilant force et vulnérabilité.",
      },
      taurus: {
        male: "L'homme Taureau valorise la stabilité, montrant souvent un mélange puissant de résilience et de sensualité.",
        female:
          'Ancrée et gracieuse, la femme Taureau apprécie la beauté et le luxe de la vie, cherchant toujours le confort.',
      },
      virgo: {
        male: "Avec un œil pour le détail, l'homme Vierge cherche la perfection, étant souvent le résolveur méthodique dans la pièce.",
        female:
          'Discriminante et assidue, la femme Vierge traverse la vie avec une compétence analytique et un cœur pur.',
      },
    },
    randomZodiacDescription: {
      templates: [
        "L'union d'un {{translate gender}} {{translate zodiac}} et d'un {{translate partnerGender}} {{translate partnerZodiac}} rassemble deux énergies astrologiques distinctes.",
        'La dynamique entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} est toujours une curiosité cosmique.',
        "Dans la tapisserie du zodiaque, les fils d'un {{translate gender}} {{translate zodiac}} s'entrelacent de manière unique avec ceux d'un {{translate partnerGender}} {{translate partnerZodiac}}.",
        "Lorsqu'un {{translate gender}} {{translate zodiac}} rencontre un {{translate partnerGender}} {{translate partnerZodiac}}, l'univers prend sûrement une pause momentanée.",
        "Un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} qui se réunissent ressemblent à deux étoiles s'alignant dans la danse céleste.",
        "L'histoire d'un {{translate gender}} {{translate zodiac}} et d'un {{translate partnerGender}} {{translate partnerZodiac}} est écrite dans les constellations.",
        'Chaque rencontre entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} détient un univers de possibilités.',
        'Le lien céleste entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} est une énigme à explorer.',
        "Les cieux chuchotent des histoires d'interactions entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}}.",
        'Un {{translate gender}} {{translate zodiac}} unissant ses forces avec un {{translate partnerGender}} {{translate partnerZodiac}} est un événement cosmique en soi.',
        'Dans la grande danse cosmique, un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} trouvent un rythme unique pour eux.',
        "Les mystères de l'univers se reflètent dans la rencontre d'un {{translate gender}} {{translate zodiac}} et d'un {{translate partnerGender}} {{translate partnerZodiac}}.",
        "Les énergies astrales d'un {{translate gender}} {{translate zodiac}} fusionnent de manière unique avec celles d'un {{translate partnerGender}} {{translate partnerZodiac}}.",
        'Entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}}, il existe un voyage interstellaire de découverte.',
        'Les étoiles scintillent différemment quand un {{translate gender}} {{translate zodiac}} croise les chemins avec un {{translate partnerGender}} {{translate partnerZodiac}}.',
        "Des merveilles cosmiques se dévoilent lorsqu'un {{translate gender}} {{translate zodiac}} rencontre un {{translate partnerGender}} {{translate partnerZodiac}}.",
        "Un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} partagent un lien qui résonne avec les mystères de l'univers.",
        "Le récit cosmique prend un tournant intéressant avec la convergence d'un {{translate gender}} {{translate zodiac}} et d'un {{translate partnerGender}} {{translate partnerZodiac}}.",
        "Dans l'immensité du cosmos, l'alliance d'un {{translate gender}} {{translate zodiac}} et d'un {{translate partnerGender}} {{translate partnerZodiac}} brille intensément.",
        "La saga céleste d'un {{translate gender}} {{translate zodiac}} rencontrant un {{translate partnerGender}} {{translate partnerZodiac}} est pour les âges astraux.",
        'Chaque interaction entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} tisse un nouveau conte étoilé.',
        "L'univers s'arrête pour témoigner de la synergie entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}}.",
        'Un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} ensemble forment une constellation de possibilités.',
        'Dans le grand théâtre du cosmos, un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} jouent un duo remarquable.',
        "Un {{translate gender}} {{translate zodiac}} partageant l'espace avec un {{translate partnerGender}} {{translate partnerZodiac}} crée une résonance céleste unique.",
        "Les galaxies hochent la tête en accord quand un {{translate gender}} {{translate zodiac}} croise le chemin d'un {{translate partnerGender}} {{translate partnerZodiac}}.",
        'Il y a une chanson cosmique que seul un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} peuvent harmoniser ensemble.',
        'Les vents astraux changent de direction quand un {{translate gender}} {{translate zodiac}} rencontre un {{translate partnerGender}} {{translate partnerZodiac}}.',
        "Dans le grand récit du zodiaque, le conte d'un {{translate gender}} {{translate zodiac}} et d'un {{translate partnerGender}} {{translate partnerZodiac}} a son propre attrait.",
        "L'interaction entre un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} est une danse cosmique aux étapes uniques.",
        "Un {{translate gender}} {{translate zodiac}} et un {{translate partnerGender}} {{translate partnerZodiac}} ensemble offrent un spectacle stellaire digne d'observation.",
      ],
    },
    input: {
      placeholder: {
        place: 'Entrez votre ville de naissance',
        date: {
          year: 'année',
          month: 'mois',
          day: 'jour',
        },
      },
    },
    gender: {
      male: 'homme',
      female: 'femelle',
    },
    general: {
      other: 'autre',
    },
    pinnedInfoButton: {
      help: 'Aide',
    },
    contactUs: {
      title: 'Nous Contacter',
      text:
        "Nous nous ferons un plaisir de vous aider par courriel à l'adresse {{supportEmail}}.<br/>" +
        'Vous pouvez également envoyer vos questions et commentaires au ',
      supportCenter: "Centre d'assistance",
    },
  },
};
